/* src/components/loadingScreen.css */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 9999;
}
.loading-screen svg{
  max-width: 10em;
  stroke: #cbcbcb00;
  stroke-linecap: round;
  fill: none;
  stroke-width: 7%;
}

.loading-screen svg use{
/* stroke: #03944A; */
animation: animate 1s linear infinite;
}
@keyframes animate {
  to{
      stroke-dashoffset: 0px;
  }
}