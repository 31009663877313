
@font-face {
  font-family: 'CustomFont';
  src: url('../public/MerchantCopyDoublesize-jE7R.ttf') format('truetype');
  /* You can define other font styles (e.g., bold, italic) similarly */
}
/* .MuiTable-root .MuiTableCell-head {
  text-transform: capitalize !important;
  font-size: 1rem !important;
} */

.sidebar {
  transition: width .3s ease-in-out !important;
  transition-delay: 600ms !important;
  margin-right: 5px;
}

/* width */
.sidebar::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
  height: 10px;
}

.company-modal::-webkit-scrollbar {
  -webkit-appearance: none;
  background-color: #F5F5F5;
  width: 7px;
}

.company-modal::-webkit-scrollbar-thumb {
  background-image:linear-gradient(rgba(0, 113, 188, 1) , rgba(0, 166, 81, 1));
  border-radius: 4px;
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  background-image:linear-gradient(rgba(0, 113, 188, 1) , rgba(0, 166, 81, 1));
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -webkit-height: 40px !important;
}

.scroll-hide::-webkit-scrollbar {
  display:none !important;
}
input[type=number] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.my-component {
  background: #fff;
}

.fullscreen-enabled .my-component {
  background: #000;
}

